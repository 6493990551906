import React, { useState, useContext } from "react";
import { AccountContext } from "../contexts/AccountContext";
import ImageFilter from "react-image-filter";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import Express from '../assets/images/express.png';

const SingleProductBundle = ({ item, products, carousel }) => {
  const [state] = useContext(AccountContext);
  const [fetching, setFetching] = useState(false);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const path = "";

  const getProductData = async (e) => {
    e.preventDefault();
    setFetching(true);

    const productId = item.product_id.toString();
    const productName = item.name
      .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
      .replace(/\s+/g, "-")
      .replace("..", "")
      .replace("/", "-")
      .replace(/---|--/g, "-")
      .replace("/", "")
      .replace("%", parseInt("%", 10));

    navigate(`${path}/${productName}/p=${productId}`);
  };

  const copyContent = (e, sku) => {
    e.preventDefault();
    e.stopPropagation();

    const input = document.createElement("input");
    input.value = sku;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);

    setCopied(true);
    setTimeout(() => setCopied(false), 2500);
  };

  return (
    <div
    className="cursor-pointer p-1  w-60"
    onClick={getProductData}
    style={{ textDecoration: "none", color: "inherit" }}
  >
    <div className="relative w-full flex justify-center items-center">
      {item.quantity === "0" && (
        <div className="absolute z-10 text-red-600 w-full text-center bottom-0">
          Out Of Stock
        </div>
      )}
      {item.quantity === "0" ? (
        <ImageFilter
          image={item.thumb}
          filter="duotone"
          colorOne={[96, 96, 96]}
          colorTwo={[255, 255, 255]}
        />
      ) : (
        <img
          alt={item.name}
          src={item.thumb}
          className="max-h-32 object-contain"
        />
      )}
    </div>
  
    <div className="text-center mt-4">
      <p className="text-gray-800 text-lg font-bold">
        {item.special !== "0" && item.special ? item.special : item.price}
      </p>
      <p className="text-gray-500 text-sm">x{item.required_quantity}</p>
      <span
        className="text-gray-700 text-xs"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(item.manufacturer_name),
        }}
      />
    </div>

  </div>
  
  );
};

export default SingleProductBundle;
